<template>
  <div class="flex items-center justify-between">
    <div
      v-if="suggestion.organisation"
      class="flex"
    >
      <organisation-view
        :organisation="suggestion.organisation"
        avatar-size="xs"
        class="mr-7"
      />
      <manager-view
        :manager="suggestion.manager"
        avatar-size="xs"
      />
    </div>
    <book-viewing-btn
      v-if="suggestion.manager?.contactsSettings?.messengers.whatsapp.phoneNumber"
      class="px-4 h-[38px]"
      span-class="md:hidden lg:inline"
      :mobile-phone="suggestion.manager?.contactsSettings?.messengers.whatsapp.phoneNumber"
      :text-to-send="textToSend"
    />
    <messengers-btns
      v-if="suggestion.manager?.contactsSettings?.messengers.whatsapp.phoneNumber || suggestion.manager?.contactsSettings?.messengers.telegram.phoneNumber || suggestion.manager?.contactsSettings?.messengers.telegram.username"
      button-class="h-[38px] px-4"
      :messengers="suggestion.manager?.contactsSettings.messengers"
    />
    <app-menu-units-select />
  </div>
</template>

<script setup lang="ts">
import ManagerView from '~/components/manager/ManagerView.vue'
import OrganisationView from '~/components/organisation/OrganisationView.vue'
import MessengersBtns from '~/components/buttons/MessengersBtns.vue'
import BookViewingBtn from '~/components/buttons/BookViewingBtn.vue'
import { useSuggestionsStore } from '~/modules/suggestions/store'

const store = useSuggestionsStore()
const route = useRoute()
const { t } = useI18n()
const { estateMarketAppUrl } = useRuntimeConfig().public

const suggestion = computed(() => store.suggestion)

const textToSend = t(
  'suggestions.buttons.bookViewing.messageForWhatsApp',
  { link: `${estateMarketAppUrl}${route.fullPath}` },
)
</script>
